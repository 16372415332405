function bindToWindowOnError(fn) {
  var oldOnError = window.onerror;

  window.onerror = function () {
    if (oldOnError) {
      oldOnError.apply(this, arguments);
    }
    fn.apply(this, arguments);
  };
}

window.OutpostErrorReporter = (function () {
  var BASE_URL = 'https://forms.hubspot.com';
  var EMBED_BASE_URL = 'https://exceptions.hs-embed-reporting.com';
  var ROUTE = 'outpost';

  function getHubletPrefix(hublet) {
    if (!hublet || hublet === 'na1') {
      return '';
    }
    return '-' + hublet;
  }

  function getBaseUrl(isEmbedApp, isQa, hublet) {
    var urlToUse = isEmbedApp ? EMBED_BASE_URL : BASE_URL;
    var hubletSuffix = getHubletPrefix(hublet);

    urlToUse = urlToUse.replace(/(\/\/[a-z]+)\./, '$1' + hubletSuffix + '.');
    if (isQa) {
      urlToUse = urlToUse.replace(/(\.com)/, 'qa$1');
    }

    urlToUse = urlToUse + '/' + ROUTE;

    return urlToUse;
  }
  function OutpostErrorReporter(projectName, opts) {
    opts = opts || {};

    if (!projectName) {
      console.warn('The projectName parameter is required');
    }

    this.projectName = projectName;
    this.env = (opts.env || 'PROD').toUpperCase();
    this.isQa = this.env === 'QA';
    this.hublet = opts.hublet || '';
    this.isEmbedApp = opts.isEmbedApp || false;
    this.level = (opts.level || 'ERROR').toUpperCase();
    this.disabled = opts.disabled || false;
    this.baseUrl =
      opts.baseUrl || getBaseUrl(this.isEmbedApp, this.isQa, this.hublet);
    this.tags = opts.tags || {};
    this.cookies = opts.cookies || {};
    this.user = opts.user || {};
    this.release = opts.release;
  }

  OutpostErrorReporter.prototype.bindToWindow = function (
    allowlistedDomainsParam,
    blocklistedErrorMessages
  ) {
    var _this = this;
    var allowlistedDomains = allowlistedDomainsParam || [];
    if (allowlistedDomains.length < 1) {
      console.warn(
        'You need to specify allowlisted domains when binding to window errors or you will catch all page errors'
      );
      return;
    }
    var blocklistedErrorMessages = blocklistedErrorMessages || [];

    bindToWindowOnError(function (message, url, line, column, ex) {
      if (
        url &&
        contains(allowlistedDomains, url) &&
        !contains(blocklistedErrorMessages, ex.message) &&
        message.toLowerCase() !== 'script error'
      ) {
        _this._sendReport('error', message, url, line, column, ex);
      }
    });
  };

  OutpostErrorReporter.prototype.report = function (error, extraContext) {
    var formattedExtraContext = {};
    try {
      var keys = Object.keys(extraContext);
      for (var index = 0; index < keys.length; index++) {
        var value = keys[index];

        if (typeof value === 'string') {
          formattedExtraContext[key] = value;
        } else {
          formattedExtraContext[key] = JSON.stringify(value);
        }
      }
    } catch (error) {
      formattedExtraContext = extraContext;
    }

    if (error) {
      console.error(error);
      this._sendReport(
        'error',
        error.message,
        error.fileName,
        error.lineNumber,
        0,
        error,
        formattedExtraContext
      );
    }
  };

  OutpostErrorReporter.prototype.debug = function (error, extraContext) {
    if (error && this.level === 'DEBUG') {
      console.debug(error);
      this._sendReport(
        'debug',
        error.message,
        error.fileName,
        error.lineNumber,
        0,
        error,
        extraContext
      );
    }
  };

  OutpostErrorReporter.prototype.addTags = function (tags) {
    extend(this.tags, tags);
  };

  OutpostErrorReporter.prototype.addCookies = function (cookies) {
    extend(this.cookies, cookies);
  };

  OutpostErrorReporter.prototype.addUserContext = function (context) {
    extend(this.user, context);
  };

  OutpostErrorReporter.prototype._sendReport = function (
    level,
    message,
    url,
    line,
    column,
    ex,
    extraContext
  ) {
    if (this.disabled) {
      console.warn(
        'Not reporting error to Outpost because logging is disabled'
      );
      return;
    }

    url =
      url ||
      (window.document.currentScript
        ? window.document.currentScript.src
        : null) ||
      window.location.href;
    line = line || 0;

    var _this2 = this;
    var report = this._buildReport(
      level,
      message,
      url,
      line,
      column,
      ex,
      extraContext
    );

    var uploadImage = new Image();
    var encodedReport = encodeURIComponent(JSON.stringify(report));
    uploadImage.src =
      this.baseUrl +
      '/' +
      this.projectName +
      '/error.gif?report=' +
      encodedReport;
    uploadImage.onload = function () {
      _this2.errorContext = {};
      console.log('Completed reporting error to ' + _this2.projectName);
    };
  };

  OutpostErrorReporter.prototype._buildReport = function (
    level,
    message,
    url,
    line,
    column,
    ex,
    extraContextParam
  ) {
    var exType = ex.name || ex;
    var extraContext = extraContextParam || {};
    var trimmedExceptionMessage;

    if (ex && ex.message) {
      trimmedExceptionMessage = ex.message.substring(0, 999);
    } else {
      trimmedExceptionMessage = message.substring(0, 999);
    }

    var report = {
      culprit: exType,
      message: trimmedExceptionMessage,
      level: level,
      exception: [
        {
          type: exType,
          value:
            (ex && ex.stack && ex.stack.substring(0, 999)) ||
            trimmedExceptionMessage,
          url: url,
        },
      ],
      request: {
        url:
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname,
        queryString: window.location.search.replace(/(^\?)/, ''),
        cookies: stringifyCookies(this.cookies),
      },
      environment: this.env,
      tags: extend(this.tags),
      user: this.user,
      release: this.release,
      extra: extraContext,
    };

    return report;
  };

  function extend(out) {
    var i, key;
    out = out || {};
    i = 1;
    while (i < arguments.length) {
      if (!arguments[i]) {
        i++;
        continue;
      }
      for (key in arguments[i]) {
        if (arguments[i].hasOwnProperty(key)) {
          out[key] = arguments[i][key];
        }
      }
      i++;
    }
    return out;
  }

  function contains(list, item) {
    try {
      if (!item) {
        return false;
      }
      for (var i = 0; i < list.length; i++) {
        if (item.indexOf(list[i]) > -1) {
          return true;
        }
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  function stringifyCookies(cookies) {
    var response = '';
    for (var cookie in cookies) {
      if (cookies.hasOwnProperty(cookie)) {
        response += cookie + '=' + cookies[cookie] + ';';
      }
    }
    return response;
  }

  return OutpostErrorReporter;
})(window.OutpostErrorReporter || {});








