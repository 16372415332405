'use es6';

export function getWidgetContent() {
  return `
    <h6>
      <div id="ka-feedback-rating-input-wrapper">
        <div class="ka-feedback-rating-question">
          <span id="ka-feedback-rating-question-text" class="ka-feedback-label-light"></span>
        </div>
        <div class="ka-feedback-rating-buttons">
          <button type="submit" id="ka-feedback-rating-btn-yes" class="ka-feedback-rating-button" name="rating" value="1">
            <span id="ka-feedback-rating-btn-yes-text"></span>
          </button>
          <button type="submit" id="ka-feedback-rating-btn-no" class="ka-feedback-rating-button" name="rating" value="0">
            <span id="ka-feedback-rating-btn-no-text"></span>
          </button>
        </div>
      </div>
      <div id="ka-feedback-post-submit-message">
        <span id="ka-feedback-post-submit-message-text" class="ka-feedback-label-light"></span>
      </div>
      <div id="ka-feedback-follow-up-form-wrapper">
        <div>
          <div>
            <span id="ka-feedback-email-input-label" class="ka-feedback-label"></span>
            <input id="ka-feedback-email-input" type="email" name="ka-feedback-email" placeholder="name@email.com" class="ka-feedback-display-block"></input>
          </div>
          <div id="ka-gdpr-consent-wrapper"></div>
          <div class="ka-feedback-double-padding-top">
            <button id="ka-feedback-email-btn-submit" class="ka-feedback-submit-button"></button>
          </div>
        </div>
      </div>
      <div id="ka-feedback-email-submitted-message" class="ka-feedback-padding-top">
        <span id="ka-feedback-email-submitted-message-text" class="ka-feedback-label"></span>
      </div>
    </h6>
    `;
}
export function getGdprImplicitConsent() {
  return `
    <div>
      <div id="ka-communication-consent">
        <p id="ka-communication-consent-text"></p>
        <div>
          <input id="ka-communication-consent-checkbox" type="checkbox"></input>
          <span id="ka-communication-consent-checkbox-label"></span>
        </div>
      </div>
      <p id="privacy-policy-text"></p>
      <p id="ka-implicit-processing-consent-text"></p>
    </div>
    `;
}
export function getGdprSeparateConsent() {
  return `
    <div>
      <div id="ka-communication-consent">
        <p id="ka-communication-consent-text"></p>
        <div>
          <input id="ka-communication-consent-checkbox" type="checkbox"></input>
          <span id="ka-communication-consent-checkbox-label"></span>
        </div>
      </div>
      <div id="ka-explicit-processing-consent">
        <p id="ka-explicit-processing-consent-text"></p>
        <div>
          <input id="ka-explicit-processing-consent-checkbox" type="checkbox">*</input>
          <span id="ka-explicit-processing-consent-checkbox-label"></span>
        </div>
      </div>
      <p id="privacy-policy-text"></p>
    </div>
    `;
}
export function getGdprLegitimateInterest() {
  return `
    <div>
      <p id="ka-legitimate-interest-privacy-policy-text"></p>
    </div>
    `;
}