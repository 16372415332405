module.exports = {
  "mode": "compressed",
  "staticDomainPrefix": "//static.hsappstatic.net",
  "bender": {
    "depVersions": {
      "feedback-knowledge-js": "static-1.1601",
      "browserslist-config-hubspot": "static-1.117",
      "csstype": "static-1.8",
      "head-dlb": "static-1.1160",
      "HeadJS": "static-2.448",
      "hs-test-utils": "static-1.3551",
      "hubspot-dlb": "static-1.1019",
      "I18n": "static-7.1167",
      "i18n-data": "static-1.173",
      "jasmine": "static-4.586",
      "jasmine-runner": "static-1.2084",
      "moment-timezone": "static-5.17",
      "outpost_static": "static-1.877",
      "PatternValidationJS": "static-1.437",
      "quartz": "static-1.2539",
      "react-utils": "static-2.1890",
      "StyleGuideUI": "static-3.404",
      "testing-library": "static-1.101",
      "react": "static-7.123",
      "enviro": "static-4.239",
      "hs-promise-rejection-tracking": "static-1.1031",
      "PortalIdParser": "static-2.221",
      "raven": "static-3.1018",
      "raven-hubspot": "static-1.1325",
      "classnames": "static-2.10",
      "hoist-non-react-statics": "static-3.9",
      "hub-http": "static-1.2184",
      "hubspot-url-utils": "static-1.647",
      "immutable": "static-2.19",
      "metrics-js": "static-1.4861",
      "react-dom": "static-7.85",
      "react-redux": "static-7.16",
      "redux": "static-4.16",
      "styled-components": "static-2.40",
      "moment": "static-3.26"
    },
    "depPathPrefixes": {
      "feedback-knowledge-js": "/feedback-knowledge-js/static-1.1601",
      "browserslist-config-hubspot": "/browserslist-config-hubspot/static-1.117",
      "csstype": "/csstype/static-1.8",
      "head-dlb": "/head-dlb/static-1.1160",
      "HeadJS": "/HeadJS/static-2.448",
      "hs-test-utils": "/hs-test-utils/static-1.3551",
      "hubspot-dlb": "/hubspot-dlb/static-1.1019",
      "I18n": "/I18n/static-7.1167",
      "i18n-data": "/i18n-data/static-1.173",
      "jasmine": "/jasmine/static-4.586",
      "jasmine-runner": "/jasmine-runner/static-1.2084",
      "moment-timezone": "/moment-timezone/static-5.17",
      "outpost_static": "/outpost_static/static-1.877",
      "PatternValidationJS": "/PatternValidationJS/static-1.437",
      "quartz": "/quartz/static-1.2539",
      "react-utils": "/react-utils/static-2.1890",
      "StyleGuideUI": "/StyleGuideUI/static-3.404",
      "testing-library": "/testing-library/static-1.101",
      "react": "/react/static-7.123",
      "enviro": "/enviro/static-4.239",
      "hs-promise-rejection-tracking": "/hs-promise-rejection-tracking/static-1.1031",
      "PortalIdParser": "/PortalIdParser/static-2.221",
      "raven": "/raven/static-3.1018",
      "raven-hubspot": "/raven-hubspot/static-1.1325",
      "classnames": "/classnames/static-2.10",
      "hoist-non-react-statics": "/hoist-non-react-statics/static-3.9",
      "hub-http": "/hub-http/static-1.2184",
      "hubspot-url-utils": "/hubspot-url-utils/static-1.647",
      "immutable": "/immutable/static-2.19",
      "metrics-js": "/metrics-js/static-1.4861",
      "react-dom": "/react-dom/static-7.85",
      "react-redux": "/react-redux/static-7.16",
      "redux": "/redux/static-4.16",
      "styled-components": "/styled-components/static-2.40",
      "moment": "/moment/static-3.26"
    },
    "project": "feedback-knowledge-js",
    "staticDomain": "//static.hsappstatic.net",
    "staticDomainPrefix": "//static.hsappstatic.net"
  }
};