'use es6';

window._hsq = window._hsq || [];
window.feedbackknowledge = window.feedbackknowledge || {};
window.feedbackknowledge.cookies = window.feedbackknowledge.cookies || {};
window._hsq.push(['addUserTokenListener', utk => {
  window.feedbackknowledge.cookies._utk = utk;
}]);
const HUBSPOT_COOKIE_NAME = 'hubspotutk';

// IE8
const trim = string => {
  if (typeof String.prototype.trim === 'function') {
    return string.trim();
  }
  return string.replace(/^\s+|\s+$/g, '');
};
const parseCookie = (name, documentCookie) => {
  if (documentCookie) {
    const cookies = documentCookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      if (typeof cookie === 'string') {
        cookie = trim(cookie);
        if (cookie.substring(0, name.length + 1) === `${name}=`) {
          return cookie.substring(name.length + 1);
        }
      }
    }
  }
  return null;
};
const getUtk = () => {
  return window.feedbackknowledge.cookies._utk || parseCookie(HUBSPOT_COOKIE_NAME, document.cookie);
};
export default {
  getUtk
};