'use es6';

/* global OutpostErrorReporter */
import api from './api';
import cookies from './cookies';
import { getGdprImplicitConsent, getGdprLegitimateInterest, getGdprSeparateConsent, getWidgetContent } from './widgetContent';
import { getSubmissionData, isBot, storeSubmissionData, validateEmail } from './utils';
let APP_ENVIRO;
let FEEDBACK_HUBAPI_DOMAIN;
let JS_FEEDBACK_DOMAIN;
let PORTAL_ID;
let ARTICLE_ID;
let KNOWLEDGE_BASE_ID;
let ARTICLE_LANGUAGE;
let UNGATED_FOR_MULTI_LANGUAGE;
let UTK;
let THANK_YOU_MESSAGE;
let IS_CONSENT_FOR_COMMUNICATION = false;
const d = document;
const w = window;
const htmlElements = {};
const FORM_CONFIG = {};
const A_MONTH_IN_MS = 1000 * 60 * 60 * 24 * 30;
const EMAIL_MESSAGE_TYPE = 'EMAIL';
const SUBMIT_MESSAGE_TYPE = 'SUBMIT';

// Functions
const getUtk = () => {
  if (!UTK) {
    UTK = cookies.getUtk();
  }
  return UTK;
};
const hideRatings = () => {
  htmlElements.ratingInputSection.style.display = 'none';
};
const showRatings = () => {
  const {
    knowledgeStrings: {
      negative,
      positive,
      question
    }
  } = FORM_CONFIG;
  htmlElements.ratingQuestionText.innerHTML = question;
  htmlElements.ratingButtonYesText.innerHTML = positive;
  htmlElements.ratingButtonNoText.innerHTML = negative;
  htmlElements.ratingInputSection.style.display = 'inherit';
};
const disableSubmitButton = el => {
  el.disabled = true;
  el.className = `${el.className} disabled`;
};
const disableFeedbackSubmitButtons = () => {
  disableSubmitButton(htmlElements.ratingButtonYes);
  disableSubmitButton(htmlElements.ratingButtonNo);
};
const enableSubmitButton = el => {
  el.disabled = false;
  el.className = el.className.replace(/\bdisabled\b/g, '');
};
const enableFeedbackSubmitButtons = () => {
  enableSubmitButton(htmlElements.ratingButtonYes);
  enableSubmitButton(htmlElements.ratingButtonNo);
};
const initializeGdprForm = () => {
  const {
    gdprSettings: {
      communicationConsentCheckboxLabel,
      communicationConsentText,
      explicitProcessingConsentText,
      explicitProcessingConsentCheckboxLabel,
      implicitProcessingConsentText,
      legitimateInterestPrivacyPolicyText,
      privacyPolicyText
    }
  } = FORM_CONFIG;
  switch (FORM_CONFIG.gdprSettings.gdprConsentType) {
    case 'CONSENT_CHECKBOX_FOR_COMMUNICATIONS_AND_SUBMIT_AS_CONSENT_TO_PROCESS':
      {
        htmlElements.gdprConsentWrapper.innerHTML = getGdprImplicitConsent();
        d.getElementById('ka-communication-consent-text').innerHTML = communicationConsentText;
        d.getElementById('ka-communication-consent-checkbox-label').innerHTML = communicationConsentCheckboxLabel;
        d.getElementById('privacy-policy-text').innerHTML = privacyPolicyText;
        d.getElementById('ka-implicit-processing-consent-text').innerHTML = implicitProcessingConsentText;
        htmlElements.communicationConsentCheckbox = d.getElementById('ka-communication-consent-checkbox');
        htmlElements.communicationConsentCheckbox.addEventListener('click', () => {
          IS_CONSENT_FOR_COMMUNICATION = htmlElements.communicationConsentCheckbox.checked;
        });
        break;
      }
    case 'CONSENT_CHECKBOXES_FOR_COMMUNICATIONS_AND_PROCESSING':
      {
        htmlElements.gdprConsentWrapper.innerHTML = getGdprSeparateConsent();
        d.getElementById('ka-communication-consent-text').innerHTML = communicationConsentText;
        d.getElementById('ka-communication-consent-checkbox-label').innerHTML = communicationConsentCheckboxLabel;
        d.getElementById('ka-explicit-processing-consent-text').innerHTML = explicitProcessingConsentText;
        d.getElementById('ka-explicit-processing-consent-checkbox-label').innerHTML = explicitProcessingConsentCheckboxLabel;
        d.getElementById('privacy-policy-text').innerHTML = privacyPolicyText;
        disableSubmitButton(htmlElements.emailButtonSubmit); // explicit processing consent needed before submit allowed

        htmlElements.communicationConsentCheckbox = d.getElementById('ka-communication-consent-checkbox');
        htmlElements.communicationConsentCheckbox.addEventListener('click', () => {
          IS_CONSENT_FOR_COMMUNICATION = htmlElements.communicationConsentCheckbox.checked;
        });
        htmlElements.explicitProcessingConsentCheckbox = d.getElementById('ka-explicit-processing-consent-checkbox');
        htmlElements.explicitProcessingConsentCheckbox.addEventListener('click', () => {
          if (htmlElements.explicitProcessingConsentCheckbox.checked) {
            enableSubmitButton(htmlElements.emailButtonSubmit);
          } else {
            disableSubmitButton(htmlElements.emailButtonSubmit);
          }
        });
        break;
      }
    case 'LEGITIMATE_INTEREST':
      {
        htmlElements.gdprConsentWrapper.innerHTML = getGdprLegitimateInterest();
        d.getElementById('ka-legitimate-interest-privacy-policy-text').innerHTML = legitimateInterestPrivacyPolicyText;
        break;
      }
    default:
      break;
  }
};
const initializeElements = () => {
  htmlElements.kaFeedbackForm = d.getElementById('ka-feedback-form');
  htmlElements.kaFeedbackForm.style.display = 'inherit';
  htmlElements.kaFeedbackForm.innerHTML = getWidgetContent();
  htmlElements.ratingInputSection = d.getElementById('ka-feedback-rating-input-wrapper');
  htmlElements.ratingQuestionText = d.getElementById('ka-feedback-rating-question-text');
  htmlElements.ratingButtonYes = d.getElementById('ka-feedback-rating-btn-yes');
  htmlElements.ratingButtonYesText = d.getElementById('ka-feedback-rating-btn-yes-text');
  htmlElements.ratingButtonNo = d.getElementById('ka-feedback-rating-btn-no');
  htmlElements.ratingButtonNoText = d.getElementById('ka-feedback-rating-btn-no-text');
  htmlElements.postSubmitMessage = d.getElementById('ka-feedback-post-submit-message');
  htmlElements.postSubmitMessage.style.display = 'none';
  htmlElements.postSubmitMessageText = d.getElementById('ka-feedback-post-submit-message-text');
  htmlElements.followUpSection = d.getElementById('ka-feedback-follow-up-form-wrapper');
  htmlElements.followUpSection.style.display = 'none';
  htmlElements.emailInputLabelText = d.getElementById('ka-feedback-email-input-label');
  htmlElements.emailInput = d.getElementById('ka-feedback-email-input');
  htmlElements.emailButtonSubmit = d.getElementById('ka-feedback-email-btn-submit');
  htmlElements.emailSubmittedMessage = d.getElementById('ka-feedback-email-submitted-message');
  htmlElements.emailSubmittedMessageText = d.getElementById('ka-feedback-email-submitted-message-text');
  showRatings();
};
const showPostSubmitMessage = postSubmitText => {
  htmlElements.postSubmitMessageText.innerHTML = postSubmitText;
  htmlElements.postSubmitMessage.style.display = 'inherit';
};
const showFollowUpSection = () => {
  const {
    knowledgeStrings: {
      leaveYourEmail,
      submitEmail
    }
  } = FORM_CONFIG;
  htmlElements.gdprConsentWrapper = d.getElementById('ka-gdpr-consent-wrapper');
  if (FORM_CONFIG.gdprSettings) {
    initializeGdprForm();
  }
  htmlElements.emailInputLabelText.innerHTML = leaveYourEmail;
  htmlElements.emailButtonSubmit.innerHTML = submitEmail;
  htmlElements.followUpSection.style.display = 'inherit';
};
const submitCallback = ({
  submissionId
}) => {
  if (submissionId) {
    FORM_CONFIG.submissionId = submissionId;
    if (!FORM_CONFIG.isDebug) {
      storeSubmissionData(submissionId, PORTAL_ID, ARTICLE_ID, FORM_CONFIG.value, getUtk());
    }
    hideRatings();
    showPostSubmitMessage(THANK_YOU_MESSAGE);
    if (!FORM_CONFIG.email && getUtk()) {
      showFollowUpSection();
    }
  } else {
    hideRatings();
    showPostSubmitMessage(FORM_CONFIG.knowledgeStrings.errorMessage);
  }
};
const handleMessage = ({
  data
}) => {
  if (data.type === SUBMIT_MESSAGE_TYPE) {
    FORM_CONFIG.value = data.value;
    api.submit(FEEDBACK_HUBAPI_DOMAIN, submitCallback, {
      portalId: PORTAL_ID,
      surveyId: FORM_CONFIG.surveyId,
      articleId: ARTICLE_ID,
      value: data.value,
      contactId: FORM_CONFIG.contactId,
      utk: getUtk(),
      onError: enableFeedbackSubmitButtons
    });
  }
  if (data.type === EMAIL_MESSAGE_TYPE) {
    htmlElements.followUpSection.style.display = 'none';
    htmlElements.emailSubmittedMessage.style.display = 'inherit';
    htmlElements.emailSubmittedMessageText.innerHTML = FORM_CONFIG.knowledgeStrings.emailSubmitted;
    const email = data.value;
    if (FORM_CONFIG.contactId) {
      api.associateEmailWithContact(FEEDBACK_HUBAPI_DOMAIN, FORM_CONFIG.contactId, email, FORM_CONFIG.submissionId, PORTAL_ID, FORM_CONFIG.gdprSettings, IS_CONSENT_FOR_COMMUNICATION);
    } else {
      api.associateEmailWithUtk(FEEDBACK_HUBAPI_DOMAIN, getUtk(), email, FORM_CONFIG.submissionId, PORTAL_ID, FORM_CONFIG.gdprSettings, IS_CONSENT_FOR_COMMUNICATION);
    }
  }
};

// Event listeners
const addEventListeners = () => {
  const doSubmit = (value, messageType) => {
    disableFeedbackSubmitButtons();
    window.postMessage({
      type: messageType,
      value
    }, '*');
  };
  const submitRating = value => {
    doSubmit(value, SUBMIT_MESSAGE_TYPE);
  };
  const submitEmail = value => {
    doSubmit(value, EMAIL_MESSAGE_TYPE);
  };
  htmlElements.ratingButtonYes.addEventListener('click', () => {
    THANK_YOU_MESSAGE = FORM_CONFIG.knowledgeStrings.thanksPositive;
    submitRating(1);
  });
  htmlElements.ratingButtonNo.addEventListener('click', () => {
    THANK_YOU_MESSAGE = FORM_CONFIG.knowledgeStrings.thanksNegative;
    submitRating(0);
  });
  htmlElements.emailButtonSubmit.addEventListener('click', () => {
    // should we do some very simple validation on the email object? let the server worry about it being completely correct
    const email = htmlElements.emailInput.value;
    if (!validateEmail(email)) {
      htmlElements.emailInput.className = `${htmlElements.emailInput.className} input-error`;
      return;
    }
    htmlElements.emailInput.className = htmlElements.emailInput.className.replace(/\binput-error\b/g, '');
    submitEmail(email);
  });
  window.addEventListener('message', handleMessage);
};
const initializeKnowledgeArticleConfig = () => {
  htmlElements.kaFeedbackFormContainer = d.getElementById('ka-feedback-form-container');
  PORTAL_ID = htmlElements.kaFeedbackFormContainer.getAttribute('portal-id');
  ARTICLE_ID = htmlElements.kaFeedbackFormContainer.getAttribute('article-id');
  KNOWLEDGE_BASE_ID = htmlElements.kaFeedbackFormContainer.getAttribute('knowledge-base-id');
  APP_ENVIRO = htmlElements.kaFeedbackFormContainer.getAttribute('enviro');
  FEEDBACK_HUBAPI_DOMAIN = htmlElements.kaFeedbackFormContainer.getAttribute('feedback-hubapi-domain');
  JS_FEEDBACK_DOMAIN = htmlElements.kaFeedbackFormContainer.getAttribute('js-feedback-domain');
  ARTICLE_LANGUAGE = htmlElements.kaFeedbackFormContainer.getAttribute('article-language-tag');
  UNGATED_FOR_MULTI_LANGUAGE = htmlElements.kaFeedbackFormContainer.getAttribute('ungated-for-multi-language');
};
const initializeOutpost = () => {
  const outpost = new OutpostErrorReporter('feedback-knowledge-js', {
    baseUri: `//:${JS_FEEDBACK_DOMAIN}.outpost`,
    cookies: {
      utk: getUtk()
    },
    env: APP_ENVIRO,
    tags: {
      portalId: PORTAL_ID
    }
  });
  outpost.bindToWindow([JS_FEEDBACK_DOMAIN]);
  w.feedbackKnowledge = w.feedbackKnowledge || {};
  w.feedbackKnowledge.outpost = outpost;
};
const fetchConfig = () => {
  if (FEEDBACK_HUBAPI_DOMAIN && PORTAL_ID && ARTICLE_ID && KNOWLEDGE_BASE_ID) {
    api.fetchConfig(FEEDBACK_HUBAPI_DOMAIN, PORTAL_ID, ARTICLE_ID, KNOWLEDGE_BASE_ID, ARTICLE_LANGUAGE, UNGATED_FOR_MULTI_LANGUAGE, getUtk(), config => {
      const {
        contactId,
        email,
        gdprSettings,
        knowledgeStrings,
        latestSubmissionTimestamp,
        surveyId
      } = config;
      FORM_CONFIG.contactId = contactId;
      FORM_CONFIG.email = email;
      FORM_CONFIG.gdprSettings = gdprSettings;
      FORM_CONFIG.knowledgeStrings = knowledgeStrings;
      FORM_CONFIG.surveyId = surveyId;
      let shouldRenderWidget = true;

      // check for existing submission client-side
      const existingSubmission = getSubmissionData(PORTAL_ID, ARTICLE_ID);
      if (existingSubmission && existingSubmission.timestamp) {
        shouldRenderWidget = Date.now() - existingSubmission.timestamp > A_MONTH_IN_MS;
      }

      // check for existing submission server-side
      if (latestSubmissionTimestamp && shouldRenderWidget) {
        shouldRenderWidget = Date.now() - latestSubmissionTimestamp > A_MONTH_IN_MS;
      }
      if (shouldRenderWidget) {
        initializeElements();
        addEventListeners();
      }
    });
  }
};

// Start
const start = () => {
  if (isBot()) return;
  initializeKnowledgeArticleConfig();
  initializeOutpost();
  fetchConfig();
};

// Start test mode - don't fetch the config from the server
const startTestMode = (surveyId, email) => {
  if (isBot()) return;
  initializeKnowledgeArticleConfig();
  FORM_CONFIG.isDebug = true;
  FORM_CONFIG.email = email;
  FORM_CONFIG.surveyId = surveyId;
  fetchConfig();
};
export default {
  start,
  startTestMode
};