/* hs-eslint ignored failing-rules */
/* eslint-disable hubspot-dev/no-unsafe-storage */

'use es6';

import EmailRegex from 'PatternValidationJS/patterns/EmailAddress';
const LOCAL_STORAGE_PREFIX = 'feedback-knowledge-js:';
const userAgent = navigator && navigator.userAgent;
export const browserSupportsCors = () => {
  return 'withCredentials' in new XMLHttpRequest();
};
const browserSupportsLocalStorage = window.localStorage && window.localStorage.getItem && window.localStorage.setItem;
export const parseJson = input => {
  try {
    return JSON.parse(input);
  } catch (e) {
    // TODO report to Sentry
    return null;
  }
};
export const validateEmail = input => EmailRegex.test(input);
export const buildWebAnalytics = articleId => {
  const webAnalytics = {
    pageId: articleId
  };
  if (document) {
    if (document.location && document.location.href) {
      webAnalytics.pageUrl = document.location.href;
    }
    if (document.title) {
      webAnalytics.pageTitle = document.title;
    }
  }
  if (userAgent) {
    webAnalytics.userAgent = userAgent;
  }
  return webAnalytics;
};
const generateSubmissionKey = (portalId, articleId) => `${LOCAL_STORAGE_PREFIX}-submission-${portalId}-${articleId}`;
export const getDomain = feedbackHubapiDomain => {
  if (window.localStorage && window.localStorage.getItem && window.localStorage.getItem('feedback-knowledge-js:local')) {
    return feedbackHubapiDomain.replace('feedback', 'local');
  } else {
    return feedbackHubapiDomain;
  }
};
export const getSubmissionData = (portalId, articleId) => {
  const key = generateSubmissionKey(portalId, articleId);
  if (browserSupportsLocalStorage) {
    return parseJson(window.localStorage.getItem(key));
  }
  return null;
};
export const storeSubmissionData = (submissionId, portalId, articleId, value, utk) => {
  if (browserSupportsLocalStorage) {
    try {
      const key = generateSubmissionKey(portalId, articleId);
      const data = {
        submissionId,
        timestamp: Date.now(),
        value,
        utk
      };
      window.localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      if (window && window.feedbackKnowledge && window.feedbackKnowledge.outpost) {
        window.feedbackKnowledge.outpost.report(error);
      }
    }
  }
};
export const isBot = () => !userAgent || /bot|python-requests|hubspot|phantomjs|bingpreview/i.test(userAgent);